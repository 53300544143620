<template>
  <div class="Settype:">
    <div class="Settype:live">
      <router-link to="/live">live</router-link>
    </div>
    <div class="Settype:post">
      <router-link to="/post">post</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.Settype\: {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &live,
  &post {
    border: var(--border);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 10px;
    width: (80%/2);
    height: (80%/2);
    font-size: 60px;
    a {
      color:var(--color);
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
    }
  }
  &live {
  }
  &post {
  }
}
</style>