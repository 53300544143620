var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Settype:" }, [
    _c(
      "div",
      { staticClass: "Settype:live" },
      [_c("router-link", { attrs: { to: "/live" } }, [_vm._v("live")])],
      1
    ),
    _c(
      "div",
      { staticClass: "Settype:post" },
      [_c("router-link", { attrs: { to: "/post" } }, [_vm._v("post")])],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }